import React, { useEffect, useState } from "react";

const Footer = () => {


    const [month, setMonth] = useState('')
    useEffect(() => {
        if (new Date().getMonth() + 1 == 1) {
            setMonth('January');
        }
        else if (new Date().getMonth() + 1 == 2) {
            setMonth('february');
        }
        else if (new Date().getMonth() + 1 == 3) {
            setMonth('March');
        }
        else if (new Date().getMonth() + 1 == 4) {
            setMonth('April');
        }
        else if (new Date().getMonth() + 1 == 5) {
            setMonth('May');
        }
        else if (new Date().getMonth() + 1 == 6) {
            setMonth('June');
        }
        else if (new Date().getMonth() + 1 == 7) {
            setMonth('July');
        }
        else if (new Date().getMonth() + 1 == 8) {
            setMonth('August');
        }
        else if (new Date().getMonth() + 1 == 9) {
            setMonth('September');
        }
        else if (new Date().getMonth() + 1 == 10) {
            setMonth('October');
        }
        else if (new Date().getMonth() + 1 == 11) {
            setMonth('November');
        }
        else if (new Date().getMonth() + 1 == 12) {
            setMonth('Dacember');
        }
    }, [new Date()])


    return (
        <div className="dash-footer">
            <div className="custom-row">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                    <h6 style={{ fontSize: 16, fontWeight: 400, color: '#050f24' , textAlign:'right' }}>Made with <span style={{ color: 'red' }}>❤</span> by STAK Solutions</h6>
                </div>
                {/* <div className="col-lg-4 col-md-4 col-sm-12">
                    <h6>Design & Developed By STAK Solutions</h6>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <h6>{new Date().getDate()} {month}  {new Date().getFullYear()} ,  Version {React.version}</h6>
                </div> */}
            </div>
        </div>
    )
}

export default Footer
