import { useEffect, useState } from "react";
import BarChart from "../../Component/ReactCharts/BarChart";
import PieChart from "../../Component/ReactCharts/PieChart";
import CommonService from '../../Services/CommonService';
import { useSelector, useDispatch } from "react-redux";
import { changeLoader } from "../../Actions/AuthAction";
import { toast } from 'react-toastify';
import { FiCommand } from 'react-icons/fi';
import { FaUserCircle } from 'react-icons/fa';
import BarChart2 from "../../Component/ReactCharts/BarChart2";


const Dashboard = ({ navigation }) => {

    const dispatch = useDispatch();
    const [data, setData] = useState();
    const [employeedata, setEmployeedata] = useState([]);
    const [userdata, setUserData] = useState([]);
    const [month, setMonth] = useState('');

    const getGraphData = async () => {
        dispatch(changeLoader(true))
        await CommonService.GraphByStatus().then((res) => {
            if (res != null) {
                if (res?.totalgrievances?.filter((x) => x?.status == 'New') == '') {
                    res.totalgrievances.push({ "count": 0, "status": "New" })
                }
                if (res?.totalgrievances?.filter((x) => x?.status == 'In Process') == '') {
                    res.totalgrievances.push({ "count": 0, "status": "In Process" })
                }
                if (res?.totalgrievances?.filter((x) => x?.status == 'Resolved') == '') {
                    res.totalgrievances.push({ "count": 0, "status": "Resolved" })
                }
                res?.grievancebydays?.map((item) => {
                    if (item?.statuscount?.filter((x) => x?.status == 'New') == '') {
                        item?.statuscount.push({ "count": 0, "status": "New" })
                    }
                    if (item?.statuscount?.filter((x) => x?.status == 'In Process') == '') {
                        item?.statuscount.push({ "count": 0, "status": "In Process" })
                    }
                    if (item?.statuscount?.filter((x) => x?.status == 'Resolved') == '') {
                        item?.statuscount.push({ "count": 0, "status": "Resolved" })
                    }
                })
                res?.grievancebysections?.map((item) => {
                    if (item?.statusCounts?.filter((x) => x?.status == 'New') == '') {
                        item?.statusCounts.push({ "count": 0, "status": "New" })
                    }
                    if (item?.statusCounts?.filter((x) => x?.status == 'In Process') == '') {
                        item?.statusCounts.push({ "count": 0, "status": "In Process" })
                    }
                    if (item?.statusCounts?.filter((x) => x?.status == 'Resolved') == '') {
                        item?.statusCounts.push({ "count": 0, "status": "Resolved" })
                    }
                })
                res?.totalgrievances?.map((item) => {
                    if (item?.status == 'New') {
                        item['color'] = '#00c0ef'
                    }
                    else if (item?.status == 'In Process') {
                        item['color'] = '#ff9900'
                    }
                    else if (item?.status == 'Resolved') {
                        item['color'] = '#4adf40'
                    }
                })
                res?.grievancebydays?.map((item) => {
                    item?.statuscount?.map((items) => {
                        if (items?.status == 'New') {
                            items['color'] = '#00c0ef'
                        }
                        else if (items?.status == 'In Process') {
                            items['color'] = '#ff9900'
                        }
                        else if (items?.status == 'Resolved') {
                            items['color'] = '#4adf40'
                        }
                    })
                })
                res?.grievancebysections?.map((item) => {
                    item?.statusCounts?.map((items) => {
                        if (items?.status == 'New') {
                            items['color'] = '#00c0ef'
                        }
                        else if (items?.status == 'In Process') {
                            items['color'] = '#ff9900'
                        }
                        else if (items?.status == 'Resolved') {
                            items['color'] = '#4adf40'
                        }
                    })
                })
                setData(res)
            }
            else {
                toast.error('Something Wrong')
            }
        })
        await CommonService.GetAllEmployee().then((res) => {
            if (res != null) {
                setEmployeedata(res)
            }
            else {
                toast.error('Something Wronng')
            }
        })
        await CommonService.GetAllUser().then((res) => {
            if (res != null) {
                setUserData(res)
            }
            else {
                toast.error('Something Wronng')
            }
        })
        dispatch(changeLoader(false))
    }

    useEffect(() => {
        if (new Date().getMonth() + 1 == 1) {
            setMonth('January');
        }
        else if (new Date().getMonth() + 1 == 2) {
            setMonth('february');
        }
        else if (new Date().getMonth() + 1 == 3) {
            setMonth('March');
        }
        else if (new Date().getMonth() + 1 == 4) {
            setMonth('April');
        }
        else if (new Date().getMonth() + 1 == 5) {
            setMonth('May');
        }
        else if (new Date().getMonth() + 1 == 6) {
            setMonth('June');
        }
        else if (new Date().getMonth() + 1 == 7) {
            setMonth('July');
        }
        else if (new Date().getMonth() + 1 == 8) {
            setMonth('August');
        }
        else if (new Date().getMonth() + 1 == 9) {
            setMonth('September');
        }
        else if (new Date().getMonth() + 1 == 10) {
            setMonth('October');
        }
        else if (new Date().getMonth() + 1 == 11) {
            setMonth('November');
        }
        else if (new Date().getMonth() + 1 == 12) {
            setMonth('Dacember');
        }
    }, [])

    useEffect(() => {
        getGraphData()
    }, [])

    let dDay = new Date().getDay() > 0 ? new Date().getDay() : 7;
    let first = (new Date().getDate() - dDay) + 1;
    let firstDayWeek = new Date(new Date().setDate(first));
    let lastDayWeek = new Date(new Date().setDate(firstDayWeek.getDate() + 6));


    return (
        <div className="dashboard-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12" style={{ marginBottom: 28 }}>
                        <div className="card-box">
                            <div className="icon" style={{ backgroundColor: '#00c0ef' }}>
                                <div className="subicon">
                                    <h3 style={{ fontSize: 20, color: '#00c0ef' }}>N</h3>
                                </div>
                            </div>
                            <div className="detail">
                                <div>
                                    <h3 style={{ fontSize: 16, color: '#000', fontWeight: 400 }}>New</h3>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <h3 style={{ fontSize: 18, color: '#6f757e', fontWeight: 500 }}>{data?.totalgrievances?.filter((x) => x?.status == 'New')[0]?.count}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12" style={{ marginBottom: 28 }}>
                        <div className="card-box">
                            <div className="icon" style={{ backgroundColor: '#ff9900' }}>
                                <div className="subicon">
                                    <h3 style={{ fontSize: 20, color: '#ff9900' }}>P</h3>
                                </div>
                            </div>
                            <div className="detail">
                                <div>
                                    <h3 style={{ fontSize: 16, color: '#000', fontWeight: 400 }}>In Process</h3>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <h3 style={{ fontSize: 18, color: '#6f757e', fontWeight: 500 }}>{data?.totalgrievances?.filter((x) => x?.status == 'In Process')[0]?.count}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12" style={{ marginBottom: 28 }}>
                        <div className="card-box">
                            <div className="icon" style={{ backgroundColor: '#4adf40' }}>
                                <div className="subicon">
                                    <h3 style={{ fontSize: 20, color: '#4adf40' }}>R</h3>
                                </div>
                            </div>
                            <div className="detail">
                                <div>
                                    <h3 style={{ fontSize: 16, color: '#000', fontWeight: 400 }}>Resolved</h3>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <h3 style={{ fontSize: 18, color: '#6f757e', fontWeight: 500 }}>{data?.totalgrievances?.filter((x) => x?.status == 'Resolved')[0]?.count}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="dashbox">
                            <div className="row">
                                <div className="dassubhheading">
                                    <h3>Grievance By Status Overview</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <BarChart
                                        graphdata={data?.totalgrievances == undefined ? [] : data?.totalgrievances}
                                        activecount={data?.totalgrievances?.filter((x) => x.count == 0)?.length}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <PieChart
                                        graphdata={data?.totalgrievances == undefined ? [] : data?.totalgrievances}
                                        type={'Total Grievance'}
                                        activecount={data?.totalgrievances?.filter((x) => x.count == 0)?.length}
                                        is3D={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="dashbox">
                            <div className="row">
                                <div className="dassubhheading">
                                    <h3>Grievance By Section Overview</h3>
                                </div>
                                {data?.grievancebysections != '' &&
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <BarChart2
                                            graphdata={data?.grievancebysections == undefined ? [] : data?.grievancebysections}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {data?.grievancebydays?.map((item, index) => {
                        return (
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{ marginBottom: 28 }} key={index + 2}>
                                <div className="card-box p-4" style={{ flexDirection: 'column' }}>
                                    <div className="dashsubheading mb-2">
                                        <h5>{item?.daytype} Grievance Overview</h5>
                                    </div>
                                    <PieChart
                                        graphdata={item?.statuscount == undefined ? [] : item?.statuscount}
                                        type={item?.daytype}
                                        activecount={item?.statuscount?.filter((x) => x.count == 0)?.length}
                                    />
                                    <div className="detail-date" style={{ display: 'flex', justifyContent: 'center' }}>
                                        {item?.daytype == 'Daily' &&
                                            <h6>Today : {((new Date().getDate()) <= 9 ? '0' + (new Date().getDate()) : (new Date().getDate())) + '-' + ((new Date().getMonth() + 1) <= 9 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)) + '-' + new Date().getFullYear()}</h6>
                                        }
                                        {item?.daytype == 'Weekly' &&
                                            <h6>Week : {firstDayWeek.toLocaleDateString()} to {lastDayWeek.toLocaleDateString()}</h6>
                                        }
                                        {item?.daytype == 'Monthly' &&
                                            <h6>Month : {month}</h6>
                                        }
                                        {item?.daytype == 'Yearly' &&
                                            <h6>Year : {new Date().getFullYear()}</h6>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )

}


export default Dashboard
