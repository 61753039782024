import { LOADER, LOGIN, LOGOUT, LOGINUSER, DASHBOARDSTRETCH } from '../Actions/ActionType';


export const changeLoader = (loading) => {
    return (dispatch, getState) => {
        dispatch({
            type: LOADER,
            loading: loading
        })
    }
};

export const ChangeSideMenu = (size) => {
    return (dispatch, getState) => {
        dispatch({
            type: DASHBOARDSTRETCH,
            size: size
        })
    }
};

export const saveLogin = (login) => {
    return async (dispatch, getState) => {
        dispatch({
            type: LOGIN,
            login: login
        })
    }
};

export const LoginUser = (loginuser) => {
    return async (dispatch, getState) => {
        dispatch({
            type: LOGINUSER,
            loginuser: loginuser
        })
    }
};

export const logout = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: LOGOUT
        })
    }
};