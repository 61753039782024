import React from "react";
import { Chart } from "react-google-charts";

const BarChart2 = ({ ...props }) => {

    const { graphdata } = props;


    var data = [
        ['Genre', 'New', 'In Process', 'Resolved', { role: 'annotation' }],
    ];

    graphdata?.map((item, index) => {
        if (item?.statusCounts?.filter(x => x.count > 0).length >= 1) {
            return data.push([item?.section, parseInt(item?.statusCounts?.filter(x => x.status == 'New')?.map((d) => { return d?.count }).toString()), parseInt(item?.statusCounts?.filter(x => x.status == 'In Process')?.map((d) => { return d?.count }).toString()), parseInt(item?.statusCounts?.filter(x => x.status == 'Resolved')?.map((d) => { return d?.count }).toString()), ''])
        }
    })


    const options = {
        title: "Section Bar Chart",
        vAxis: { title: "Sections" },
        hAxis: { title: "Numbers" },
        seriesType: "bars",
        legend: { position: 'top', maxLines: 3 },
        bar: { groupWidth: '75%' },
        isStacked: true,
        colors: ['#00c0ef', '#ff9900', '#4adf40'],
        series: { 3: { type: "line" } },
    };

    return (
        <Chart
            chartType="BarChart"
            width="100%"
            height={graphdata == '' ? '800px' : `${Math.round(Math.abs(data?.length/2))}00px`}
            data={data}
            options={options}
        />
    );
}

export default BarChart2
