import React, { useEffect } from 'react';
import SideMenue from '../Component/DashboardComponent/SideMenue';
import { Outlet } from 'react-router-dom';
import Header from '../Component/DashboardComponent/Header';
import Footer from '../Component/DashboardComponent/Footer';
import useWindowDimensions from '../Utils/WindowSize';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeSideMenu } from '../Actions/AuthAction';


const DashboardLayout = () => {

    const { width, height } = useWindowDimensions();
    const dispatch = useDispatch();

    const mobilemode = useSelector((state) => state.AuthReducer.size)

    useEffect(() => {
        if (width > 1190) {
            dispatch(ChangeSideMenu(false))
        }
    }, [width])

    return (
        <React.Fragment>
            <div className="dashboard-layout">
                <div className='dashboard-wraper'>
                    <div className='dashboard-row'>
                        <div className={`dashsidecol ${mobilemode ? 'responsive' : ""}`} style={{ width: 290, padding: 30 }}>
                            <SideMenue />
                        </div>
                        <div className='dashboardcol'>
                            <header>
                                <Header />
                            </header>
                            <main style={{ minHeight: isNaN(height) ? 500 : height - 70 }}>
                                <Outlet />
                            </main>
                            <footer>
                                <Footer />
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DashboardLayout;