import React from "react";
import { Chart } from "react-google-charts";

const BarChart = ({ ...props }) => {

  const { graphdata, activecount } = props;

  const data = [
    [],
  ];

  graphdata?.map((item, index) => {
    if (!data[0]?.includes("Month")) {
      data[0].push("Month")
    }
    if (index == 0) {
      return data.push([item?.status + " " + `(${item?.count})`, item?.count, 0, 0, item?.count]), data[0].push(item?.status)
    }
    if (index == 1) {
      return data.push([item?.status + " " + `(${item?.count})`, 0, item?.count, 0, item?.count]), data[0].push(item?.status)
    }
    if (index == 2) {
      return data.push([item?.status + " " + `(${item?.count})`, 0, 0, item?.count, item?.count]), data[0].push(item?.status)
    }
  })


  const options = {
    title: "Total Grievance Column Chart",
    vAxis: { title: "Grievance" },
    hAxis: { title: "Status" },
    seriesType: "bars",
    series: { 3: { type: "line" } },
    colors: [...graphdata?.map((item, index) => { return item?.color }), ...['red']]
  };
  data[0].push("Average")


  return (
    activecount == 3 ?
      <div style={{ width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h5 style={{ color: '#ce5c5c' }}>No Grievance Found</h5>
      </div>
      :
      <Chart
        chartType="ComboChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
  );
}

export default BarChart
