import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import CommonService from '../../Services/CommonService';
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { changeLoader } from "../../Actions/AuthAction";
import { toast } from 'react-toastify';

const DashboardReportDetail = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const id = state;

    const [data, setData] = useState();

    const getDetails = async () => {
        dispatch(changeLoader(true))
        await CommonService.ComplaintDetail(id).then((res) => {
            if (res != null) {
                if (res?.success) {
                    setData(res?.data)
                }
                else {
                    toast.error(res?.message)
                }
            }
        })
        dispatch(changeLoader(false))
    }

    useEffect(() => {
        if (id == null) {
            navigate('/dashreport')
        }
        getDetails()
    }, [id])


    return (
        <div className="dashboard-detail">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                        <div style={{ width: '100%' }}>
                            <div className='filterbtn' onClick={() => { navigate('/dashreport') }}>
                                <MdOutlineKeyboardBackspace size={22} />
                                <h5 style={{ fontWeight: '400', marginBottom: 0 , fontSize:16 }}>Back</h5>
                            </div>
                        </div>
                    </div>
                    {data != undefined &&
                        <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                            <div className="detail-box">
                                <div className="detail-row">
                                    <div className="label">
                                        <h5>Complaint Id</h5>
                                    </div>
                                    <div className="detailcolon">
                                        <h5>:</h5>
                                    </div>
                                    <div className="detail">
                                        <h5>{data?.id}</h5>
                                    </div>
                                </div>
                                {data?.cdate != null &&
                                    <div className="detail-row">
                                        <div className="label">
                                            <h5>Complaint Date</h5>
                                        </div>
                                        <div className="detailcolon">
                                            <h5>:</h5>
                                        </div>
                                        <div className="detail">
                                            <h5>{data?.cdate}</h5>
                                        </div>
                                    </div>
                                }
                                <div className="detail-row">
                                    <div className="label">
                                        <h5>Name</h5>
                                    </div>
                                    <div className="detailcolon">
                                        <h5>:</h5>
                                    </div>
                                    <div className="detail">
                                        <h5>{data?.name}</h5>
                                    </div>
                                </div>
                                <div className="detail-row">
                                    <div className="label">
                                        <h5>Mobile</h5>
                                    </div>
                                    <div className="detailcolon">
                                        <h5>:</h5>
                                    </div>
                                    <div className="detail">
                                        <h5>{data?.mobile}</h5>
                                    </div>
                                </div>
                                <div className="detail-row">
                                    <div className="label">
                                        <h5>Ward</h5>
                                    </div>
                                    <div className="detailcolon">
                                        <h5>:</h5>
                                    </div>
                                    <div className="detail">
                                        <h5>{data?.ward}</h5>
                                    </div>
                                </div>
                                <div className="detail-row">
                                    <div className="label">
                                        <h5>Section</h5>
                                    </div>
                                    <div className="detailcolon">
                                        <h5>:</h5>
                                    </div>
                                    <div className="detail">
                                        <h5>{data?.sectionNavigation?.section}</h5>
                                    </div>
                                </div>
                                <div className="detail-row">
                                    <div className="label">
                                        <h5>Grievance</h5>
                                    </div>
                                    <div className="detailcolon">
                                        <h5>:</h5>
                                    </div>
                                    <div className="detail">
                                        <h5>{data?.complaint}</h5>
                                    </div>
                                </div>
                                {data?.sdate != null &&
                                    <div className="detail-row">
                                        <div className="label">
                                            <h5>Seen Date</h5>
                                        </div>
                                        <div className="detailcolon">
                                            <h5>:</h5>
                                        </div>
                                        <div className="detail">
                                            <h5>{data?.sdate}</h5>
                                        </div>
                                    </div>
                                }
                                <div className="detail-row">
                                    <div className="label">
                                        <h5>Status</h5>
                                    </div>
                                    <div className="detailcolon">
                                        <h5>:</h5>
                                    </div>
                                    <div className="detail">
                                        <h5>{data?.status}</h5>
                                    </div>
                                </div>
                                {data?.rdate != null &&
                                    <div className="detail-row">
                                        <div className="label">
                                            <h5>Resolved Date</h5>
                                        </div>
                                        <div className="detailcolon">
                                            <h5>:</h5>
                                        </div>
                                        <div className="detail">
                                            <h5>{data?.rdate}</h5>
                                        </div>
                                    </div>
                                }
                                {(data?.remarks != null && data?.remarks != "") &&
                                    <div className="detail-row">
                                        <div className="label">
                                            <h5>Remarks</h5>
                                        </div>
                                        <div className="detailcolon">
                                            <h5>:</h5>
                                        </div>
                                        <div className="detail">
                                            <h5>{data?.remarks}</h5>
                                        </div>
                                    </div>
                                }
                                {(data?.feedback != null && data?.feedback != "") &&
                                    <div className="detail-row">
                                        <div className="label">
                                            <h5>Feedback</h5>
                                        </div>
                                        <div className="detailcolon">
                                            <h5>:</h5>
                                        </div>
                                        <div className="detail">
                                            <h5>{data?.feedback}</h5>
                                        </div>
                                    </div>
                                }
                                <div className="detail-image">
                                    {data?.oldimg != '' &&
                                        <div className="image">
                                            <h4>Old Image</h4>
                                            <img src={data?.oldimg} alt="image" />
                                        </div>
                                    }
                                    {data?.newimg != '' &&
                                        <div className="image">
                                            <h4>New Image</h4>
                                            <img src={data?.newimg} alt="image" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default DashboardReportDetail