import React, { useState } from "react";
import { Chart } from "react-google-charts";

const PieChart = ({ navigation, ...props }) => {

    const { graphdata, type, is3D = false, activecount } = props;

    const data = [
        ["Task", "Hours per Day"],
    ];

    graphdata?.map((item, index) => {
        return (
            data.push([item?.status, item?.count])
        )
    })

    const options = {
        title: `${type} ${is3D ? '3D' : 'Pie'} Chart`,
        pieHole: 0.3,
        is3D: is3D,
        colors: [...graphdata?.map((item, index) => { return item?.color }), ...['red']]
    };

    return (
        activecount == 3 ?
            <div style={{ width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h5 style={{color:'#ce5c5c'}}>No Grievance Found</h5>
            </div>
            :
            <Chart
                chartType="PieChart"
                width="100%"
                height="400px"
                data={data}
                options={options}
            />
    );
}

export default PieChart
