import RestClient from "../Utils/RestClient";
import { host } from "../Utils/Host";


const Login = (username, password) => {
    let payload = {
        username: username,
        password: password
    }
    return RestClient.POST(`${host}api/UserAccount/AuthenticateUser`, payload)
}

const GraphByStatus = () => {
    return RestClient.GET(`${host}api/Admin/GetDashboard`)
}

const GetAllEmployee = () => {
    return RestClient.GET(`${host}api/Employee/GetAllEmployee`)
}

const GetAllUser = () => {
    return RestClient.GET(`${host}api/UserAccount/GetAllUsers`)
}

const AddNewUser = (username, password) => {
    let payload = {
        username: username,
        password: password,
        active: true
    }
    return RestClient.POST(`${host}api/UserAccount/AddUserAccount`, payload)
}

const DeleteUser = (id) => {
    return RestClient.PUT(`${host}api/UserAccount/DeleteUserAccount?arg=${id}`)
}

const GetComplaintbyStatus = (status) => {
    return RestClient.GET(`${host}api/Admin/GetComplaintbyStatus?arg=${status}`)
}

const ComplaintDetail = (id) => {
    return RestClient.GET(`${host}api/Complaint/GetCompliantByID/id?id=${id}`)
}

const AddNewEmployee = (name, mobile, role, check) => {
    let payload = {
        name: name,
        mobile: mobile,
        role: role,
        active: check,
    }
    return RestClient.POST(`${host}api/Employee/AddEmployee`, payload)
}

const UpdateSections = (empid, sections) => {
    let payload = {
        empid: empid,
        sections: sections
    }
    return RestClient.POST(`${host}api/Employee/AddUpdateSections`, payload)
}

const ChangePassword = (payload) => {
    return RestClient.PUT(`${host}api/UserAccount/ChangePassword`, payload)
}

const GetAllSection = () => {
    return RestClient.GET(`${host}api/Services/GetAllSection`)
}

const GetAllWard = () => {
    return RestClient.GET(`${host}api/Services/GetAllWard`)
}

const EditEmployee = (payload) => {
    return RestClient.PUT(`${host}api/Employee/EditEmployee`, payload)
}

const DeletEmployee = (id, name, mobile) => {
    let payload = {
        "data": {
            id: id,
            name: name,
            mobile: mobile,
        }
    }
    return RestClient.DELETE(`${host}api/Employee/deleteEmployee`, payload)
}

const GenerateReport = (status, ward, sectionid, startdate, enddate) => {
    let payload = {
        sectionid: sectionid,
        ward: ward,
        status: status,
        startdate: startdate,
        enddate: enddate,
    }
    return RestClient.POST(`${host}api/Admin/GetComplaintbyCriteria`, payload)
}

const GenerateReportPDF = (status, ward, sectionid, startdate, enddate) => {
    let payload = {
        sectionid: sectionid,
        ward: ward,
        status: status,
        startdate: startdate,
        enddate: enddate,
    }
    return RestClient.POST(`${host}api/Admin/GetComplaintReportPDF`, payload)
}

const DataDelet = (number, doc) => {
    let payload = {
        mobile: number,
        identitydoc: doc ?? 0
    }
    return RestClient.POST(`${host}api/Complaint/RequestDataDelet`, payload)
}

const uploadAll = {
    Login, GraphByStatus, GetAllEmployee, AddNewEmployee, ChangePassword, GetComplaintbyStatus,
    GetAllSection, GetAllWard, EditEmployee, DeletEmployee, GenerateReport, ComplaintDetail,
    GenerateReportPDF, GetAllUser, AddNewUser, DeleteUser, UpdateSections, DataDelet
}

export default uploadAll
