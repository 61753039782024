import React from "react";
import { Vortex } from "react-loader-spinner";
import { useSelector } from "react-redux";

const Loader = () => {

    const loader = useSelector((state) => state.AuthReducer.loading)

    return (
        <>
            {loader &&
                <div className="loader">
                    {/* <h2 style={{ fontSize: 18, position: 'absolute', color: 'green' }}>Loading...</h2> */}
                    <Vortex
                        visible={true}
                        height="120"
                        width="120"
                        ariaLabel="vortex-loading"
                        wrapperStyle={{}}
                        wrapperClass="vortex-wrapper"
                        colors={['grey', '#f0f8ff', '#85e194', 'yellow', '#b7efef', 'orange']}
                    />
                </div>
            }
        </>
    );
}

export default Loader