import React, { useEffect, useState } from "react";
import TextInput from "../Component/TextInput";
import Button from "../Component/Button";
import CommonService from '../Services/CommonService';
import { useDispatch } from "react-redux";
import { changeLoader } from '../Actions/AuthAction';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


const AccountDelete = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [selectedFile, setSelectedFile] = useState(null);
    const [mobile, setMobile] = useState('');
    const [base64, setBase64] = useState(null);
    const [showFileInput, setShowFileInput] = useState(true);

    const fileInputRef = React.useRef(null);

    useEffect(() => {
        setBase64(null)
        setMobile('')
        setShowFileInput(true)
        setSelectedFile(null)
    }, [])

    const handleFileChange = (event) => {
        const reader = new FileReader();
        const file = event.target.files[0];
        if (file) {
            reader.onload = function (event) {
                const result = event.target.result;
                setBase64(result)
            };
            reader.readAsDataURL(file);
            setSelectedFile(file);
        }
    };

    const onSend = async () => {
        dispatch(changeLoader(true))
        await CommonService.DataDelet(mobile, base64).then((res) => {
            if (res != null) {
                if (res?.success) {
                    toast.success('Successfully Delete')
                }
                else {
                    toast.error(res?.message)
                }
            }
        })
        dispatch(changeLoader(false))
    }

    const DeleteAttachment = () => {
        setBase64(null)
        setSelectedFile(null)
        setShowFileInput(false);
        setTimeout(() => {
            setShowFileInput(true);
        }, 0);
    };

    return (
        <div className="login-page public-page page">
            <div className="login-wrapper">
                <div className="login-box">
                    <div className="logo">
                        <img src="/assets/logo.jpg" alt="logo" width={40} />
                        <div className="heading">
                            <h2>Bhagalpur Municipal Corporation</h2>
                        </div>
                    </div>
                    <div className="login-input">
                        <h3>Delete Record</h3>
                        <div className="currency-input">
                            <img className="currency-flag" src="/assets/flag.png" alt="Currency Flag" />
                            <p>+91</p>
                            <div className="box-input">
                                <TextInput
                                    isRequired={true}
                                    type='number'
                                    label={'Mobile Number'}
                                    value={mobile}
                                    onchange={(e) => setMobile(e.target.value)}
                                />
                            </div>
                        </div>
                        {selectedFile && (
                            <div style={{ paddingBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {selectedFile.type.startsWith('image/') ?
                                    <img
                                        src={URL.createObjectURL(selectedFile)}
                                        alt="Selected"
                                        style={{ maxWidth: '100%', maxHeight: '200px' }}
                                    />
                                    :
                                    selectedFile.type === 'application/pdf' ?
                                        <embed
                                            src={URL.createObjectURL(selectedFile)}
                                            type="application/pdf"
                                            width="100%"
                                            height="400px"
                                        />
                                        :
                                        <p>This file type is not supported.</p>
                                }
                            </div>
                        )}
                        <div className="detail">
                            {showFileInput &&
                                <input style={{ display: 'none' }} type="file" accept=".jpg, .jpeg, .png, .pdf" ref={fileInputRef} onChange={(e) => { handleFileChange(e) }} />
                            }
                            <div style={{ marginBottom: 15, width: 'max-content', minWidth: 200 }}>
                                {(selectedFile == null && base64 == null) ?
                                    <Button
                                        value="Upload Attachment"
                                        NewclassName="upload-btn"
                                        onClick={() => { fileInputRef.current.click() }}
                                    />
                                    :
                                    <Button
                                        value="Delete Attachment"
                                        NewclassName="delete-btn"
                                        onClick={() => { DeleteAttachment() }}
                                    />
                                }
                            </div>
                        </div>
                        <Button
                            value="Delete"
                            disabled={ (mobile ==  '' || mobile?.length < 9) ? true : false}
                            onClick={() => { onSend() }}
                        />
                        <div className="delet-data" style={{ width: '100%', marginTop: 20 }} onClick={() => { navigate('/') }}>
                            <h5 style={{ textAlign: 'center', fontSize: 13, fontWeight: 400, color: '#6f757e' }}>Back to<span style={{ color: '#ff8e29', cursor: 'pointer', marginLeft: 5 }}>Log in</span></h5>
                        </div>
                    </div>
                </div>
                <div className="login-banner">
                    <img src="/assets/login-img.png" alt="Login banner" width={'100%'} height={'100%'} />
                </div>
            </div>
        </div >
    )
}

export default AccountDelete