import React, { useState, useEffect } from "react";
import ReactPaginate from 'react-paginate';
import Form from 'react-bootstrap/Form';


const CustomPagination = ({ items, TotalItems, component, onChangePage }) => {

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [formpage, setFormpage] = useState(1);
    const [topage, setTopage] = useState(itemsPerPage)


    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(TotalItems / itemsPerPage));
    }, [itemOffset, itemsPerPage, items]);


    useEffect(() => {
        if (pageCount === 1) {
            setTopage(pageCount)
            setFormpage(1)
        }
        else {
            setFormpage(1)
            setTopage(itemsPerPage)
        }
    }, [pageCount])


    const handlePageClick = (event) => {
        setFormpage(event.selected === 0 ? 1 : (event.selected * itemsPerPage) + 1)
        setTopage(pageCount === event.selected + 1 ? TotalItems : (event.selected + 1) * itemsPerPage)
        setCurrentItems();
        onChangePage(event.selected + 1, event.selected * itemsPerPage)
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };


    return (
        <>
            {component(currentItems)}
            <div className="pageination-row" style={items?.length == 0 ? {justifyContent:'center'} : {justifyContent:'space-between'}}>
                <div>
                    {items?.length > 0 ?
                        <h5>{'showing'} {formpage} - {pageCount == 1 ? TotalItems : topage} {'of Total'} {TotalItems}</h5>
                        :
                        <h5>{'No Items'}</h5>
                    }
                </div>
                {items?.length > 0 &&
                    <div style={{ width: 100 }}>
                        <Form.Select aria-label="Default select example"
                            onChange={e => {
                                if (e.target.value == 'All') {
                                    setItemsPerPage(TotalItems)
                                }
                                else {
                                    setItemsPerPage(e.target.value)
                                }
                            }}>
                            <option value="10">10</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="All">All</option>
                        </Form.Select>
                    </div>
                }
                <ReactPaginate
                    nextLabel={'next'}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={'prev'}
                    pageClassName="pageinate-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
        </>

    );
}

export default CustomPagination