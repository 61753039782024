import React, { useState } from "react";
import TextInput from "../Component/TextInput";
import Button from "../Component/Button";
import CommonService from '../Services/CommonService';
import { useDispatch } from "react-redux";
import { changeLoader, saveLogin, LoginUser } from '../Actions/AuthAction';
import { toast } from 'react-toastify';
import LocalStorage from '../Utils/LocalStorage';
import Cookies from 'universal-cookie';
import { FaUser } from "react-icons/fa";
import { MdKey, MdKeyOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";


const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cookies = new Cookies();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [show, setShow] = useState(false);

    const onLogin = async () => {
        dispatch(changeLoader(true))
        await CommonService.Login(username, password).then(async (res) => {
            if (res != null) {
                if (res?.success) {
                    res['data']['password'] = password
                    cookies.set('Login', 'true', { path: '/' });
                    dispatch(saveLogin('login'));
                    dispatch(LoginUser(res?.data));
                    await LocalStorage.SetData('loginuser', JSON.stringify(res?.data))
                    await LocalStorage.SetData('login', 'login')
                    setPassword('')
                    setUsername('')
                    window.location.reload()
                    toast.success(res?.message)
                }
                else {
                    toast.error(res?.message)
                }
            }
        })
        dispatch(changeLoader(false))
    }

    return (
        <div className="login-page page">
            <div className="login-wrapper">
                <div className="login-box">
                    <div className="logo">
                        <img src="assets/logo.jpg" alt="logo" width={40} />
                        <div className="heading">
                            <h2>Bhagalpur Municipal Corporation</h2>
                        </div>
                    </div>
                    <div className="login-input">
                        <h3>Login</h3>
                        <div style={{ width: '100%', marginTop: 10 }}>
                            <TextInput
                                label={'Username'}
                                value={username}
                                placeholder={'Enter Your Username'}
                                lefticon={<FaUser fontSize={15} color="6f757e" />}
                                onchange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div style={{ width: '100%', marginTop: 10 }}>
                            <TextInput
                                type={show ? "text" : "password"}
                                inputtype={'password'}
                                lefticon={!show ? <MdKey size={20} color="#6f757e" /> : <MdKeyOff size={20} color="#6f757e" />}
                                label={'Password'}
                                placeholder={'********'}
                                value={password}
                                show={show}
                                onIconClick={() => { setShow(!show) }}
                                onchange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div style={{ width: '100%', marginTop: 10 }}>
                            <Button
                                value="Log in"
                                disabled={username == '' || password == '' ? true : false}
                                onClick={() => { onLogin() }}
                            />
                        </div>
                        <div className="delet-data" style={{ width: '100%' }} onClick={() => { navigate('/record/delete') }}>
                            <h5 style={{ textAlign: 'center', fontSize: 13, fontWeight: 400, color: '#ff8e29', cursor: 'pointer' }}>Request Data Delete</h5>
                        </div>
                    </div>
                </div>
                <div className="login-banner">
                    <img src="assets/login-img.png" alt="Login banner" width={'100%'} height={'100%'} />
                </div>
            </div>
        </div>
    )
}

export default Login