import { Form } from 'react-bootstrap';

const CustomCheckbox = ({ ...props }) => {

    const { label, type, onChecked, defaultChecked, defaultValue, disabled, checked, value , id } = props;


    return (
        <Form>
            <Form.Check
                type={type}
                label={label}
                id={id}
                defaultChecked={defaultChecked}
                defaultValue={defaultValue}
                value={value}
                disabled={disabled}
                onChange={onChecked}
                checked={checked}
            />
        </Form>
    )
}

export default CustomCheckbox