import React, { useState } from "react";
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { FaHome, FaUserCircle } from 'react-icons/fa';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useSelector, useDispatch } from "react-redux";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import LocalStorage from "../../Utils/LocalStorage";
import { ChangeSideMenu, saveLogin } from '../../Actions/AuthAction';
import { IoIosMenu, IoIosSettings } from "react-icons/io";


const Header = () => {

    const cookies = new Cookies();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginuser = useSelector((state) => state.AuthReducer.loginuser)

    const onLogout = async () => {
        cookies.set('Login', 'false', { path: '/' });
        dispatch(saveLogin('not login'));
        await LocalStorage.RemoveData('loginuser')
        await LocalStorage.SetData('login', 'not login')
        window.location.replace(window.location.origin + '/')
    }

    return (
        <div className="dash-head">
            <div className="custom-row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="header-menu">
                        <div className="menu-icon" onClick={() => { dispatch(ChangeSideMenu(true)) }}>
                            <IoIosMenu size={28} style={{ cursor: 'pointer' }} />
                        </div>
                        <h6 style={{ fontSize: 18, fontWeight: '500', color: '#000', textTransform: 'uppercase' }}>Bhagalpur Municipal Corporation</h6>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="dropdown-row">
                        <div className="icon">
                            <FaUserCircle size={35} color="#fca3b5" />
                        </div>
                        <div className="name">
                            <h3 style={{ fontSize: 17, color: '#050f24', fontWeight: 500, marginLeft: 8 }}>{loginuser?.username}</h3>
                        </div>
                        <div className="drop-icon">
                            <DropdownButton id="dropdown-basic-button">
                                <Dropdown.Item onClick={() => { navigate('/') }}> <FaHome siz={20} style={{ marginRight: 20, color: '000', fontSize: 14 }} />{'Home'}</Dropdown.Item>
                                <Dropdown.Item onClick={() => { navigate('/dashsetting') }}> <IoIosSettings size={18} style={{ marginRight: 20, color: '000', fontSize: 14 }} />{'Setting'}</Dropdown.Item>
                                <Dropdown.Item onClick={() => { onLogout() }} style={{ borderTop: '1px solid #f3eeee', color: '#f54f5f' }}> <RiLogoutCircleRLine size={18} style={{ marginRight: 20, color: 'f54f5f', fontSize: 14 }} />{'Logout'}</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header