
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Button';
import TextInput from '../TextInput';
import CommonService from '../../Services/CommonService';
import DropDown from '../Dropdown';
import { useDispatch } from 'react-redux';
import { changeLoader } from '../../Actions/AuthAction';
import { toast } from 'react-toastify';
import CustomCheckbox from '../CustomCheckbox';
import useWindowDimensions from '../../Utils/WindowSize';


const AddEmployee = ({ showmodal, Hidemodal }) => {

    const dispatch = useDispatch();
    const { height } = useWindowDimensions();

    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [check, setCheck] = useState(false);
    const [sectionlist, setSectionList] = useState([]);
    const [selectedsection, setSelectedSection] = useState([])
    const [selectedrole, setSelectedRole] = useState()

    const UserRole = [
        { 'label': 'Section-Incharge', 'value': 'Section-Incharge' },
        { 'label': 'Supervisor', 'value': 'Supervisor' }
    ]

    const getSectinons = async () => {
        await CommonService.GetAllSection().then((res) => {
            if (res != null) {
                setSectionList(res)
            }
        })
    }

    useEffect(() => {
        getSectinons()
    }, [])


    const onSave = async () => {
        dispatch(changeLoader(true))
        await CommonService.AddNewEmployee(name, mobile, selectedrole?.value, check).then(async (res) => {
            if (res != null) {
                if (res?.success) {
                    if (selectedsection?.length > 0) {
                        await CommonService.UpdateSections(res?.data?.id, selectedsection)
                    }
                    toast.success(res?.message)
                    Hidemodal('refresh')
                }
                else {
                    toast.error(res?.message)
                }
            }
        })
        dispatch(changeLoader(false))
    }

    return (
        <>
            <Modal
                show={showmodal}
                onHide={Hidemodal}
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body style={{ width: '100%', maxHeight: height - 50, minWidth: 600, borderRadius: 5, backgroundColor: '#fff', padding: 20, overflowY: 'auto' }}>
                    <div className='verify-popup'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='verify-header'>
                                    <h3 style={{ textAlign: 'center', color: '#CCC' }}>{'Add Employee'}</h3>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='edit-form'>
                                    <div className='mt-3'>
                                        <TextInput
                                            isRequired={true}
                                            label={'Enter name'}
                                            value={name}
                                            onchange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <TextInput
                                            isRequired={true}
                                            type="number"
                                            label={'Enter Phone'}
                                            value={mobile}
                                            onchange={(e) => setMobile(e.target.value)}
                                        />
                                    </div>
                                    {sectionlist != '' &&
                                        <>
                                            <div className='heading-status mt-3'>
                                                <h6>Section</h6>
                                            </div>
                                            <div className='mt-2'>
                                                <DropDown
                                                    placeholder={'Select Section'}
                                                    Multiple={true}
                                                    options={sectionlist?.map((item, index) => { return { label: item.section, value: item?.id } })}
                                                    onChange={(item) => {
                                                        let tempid = [];
                                                        item?.forEach((item, index) => {
                                                            let id = item?.value
                                                            tempid.push(id)
                                                        })
                                                        setSelectedSection(tempid)
                                                    }
                                                    }
                                                />
                                            </div>
                                        </>
                                    }
                                    <div className='heading-status mt-3'>
                                        <h6>Role</h6>
                                    </div>
                                    <div className='mt-2'>
                                        <DropDown
                                            placeholder={'Select Role'}
                                            options={UserRole?.map((item, index) => { return { label: item.label, value: item?.value } })}
                                            value={selectedrole}
                                            onChange={({ value }) => { setSelectedRole({ value: value, label: value }) }}
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <CustomCheckbox
                                            type={'Checkbox'}
                                            label={'Active'}
                                            checked={check}
                                            onChecked={(e) => { setCheck(e.target.checked) }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12 mt-3'>
                                <div className='editbtn'>
                                    <Button
                                        value={'Save'}
                                        disabled={(name == '' || mobile == '' || selectedrole == undefined) ? true : false}
                                        onClick={() => { onSave() }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}


export default AddEmployee
