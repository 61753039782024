import React, { useState, useEffect } from "react";
import CustomPagination from "../../Component/CustomPagination";
import { useNavigate } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle, IoMdSearch } from 'react-icons/io';
import { FiEdit3 } from 'react-icons/fi';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaUserCircle } from 'react-icons/fa';
import CommonService from '../../Services/CommonService';
import { useDispatch } from "react-redux";
import { changeLoader } from "../../Actions/AuthAction";
import { toast } from 'react-toastify';
import TextInput from '../../Component/TextInput';
import EditEmployee from '../../Component/DashboardComponent/EditEmployee';
import VerificationPopup from '../../Component/VerificationPopup';
import AddEmployee from "../../Component/DashboardComponent/AddEmployee";


const DashboardCustomer = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [hide, setHide] = useState(false);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [role, setRole] = useState('');
    const [customerlist, setCustomerList] = useState([]);
    const [editdata, setEditData] = useState([]);
    const [showedit, setShowEdit] = useState(false);
    const [deletdata, setDeletData] = useState([]);
    const [showdelet, setShowDelet] = useState(false);
    const [showadd, setShowAdd] = useState(false);
    const [search, setSearch] = useState('');


    const getAllEmployee = async () => {
        dispatch(changeLoader(true))
        await CommonService.GetAllEmployee().then((res) => {
            if (res != null) {
                res?.forEach((item) => {
                    item['section'] = item?.empSec[0]?.section?.section == undefined ? "Not Assign" : item?.empSec?.map((x) => { return x?.section?.section }).toString()
                })
                setCustomerList(res)
            }
            else {
                toast.error('Something Wronng')
            }
        })
        dispatch(changeLoader(false))
    }

    useEffect(() => {
        getAllEmployee()
    }, [])

    const onHideEdit = (e) => {
        setEditData([])
        setShowEdit(false)
        if (e == 'refresh') {
            getAllEmployee()
        }
    }

    const onHideDelet = (e) => {
        setDeletData([])
        setShowDelet(false)
    }

    const onHideAdd = (e) => {
        setShowAdd(false)
        if (e == 'refresh') {
            getAllEmployee()
        }
    }

    const onDelet = async () => {
        dispatch(changeLoader(true))
        await CommonService.DeletEmployee(deletdata?.id, deletdata?.name, deletdata?.mobile).then((res) => {
            if (res != null) {
                if (res?.success) {
                    toast.success(res?.message)
                    setShowDelet(false)
                    setDeletData([])
                    getAllEmployee()
                }
                else {
                    toast.error(res?.message)
                }
            }
        })
        dispatch(changeLoader(false))
    }


    return (
        <>
            <div className="dashboard-customer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <TextInput
                                placeholder={'Search..'}
                                value={search}
                                lefticon={<IoMdSearch size={20} />}
                                onchange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="buttons">
                                <input type={'button'} value='Add New' className="dash-btn Add-button" onClick={() => { setShowAdd(true) }} />
                                <ReactHTMLTableToExcel
                                    filename="employeelist"
                                    sheet="EmployeeSheet"
                                    table="table-to-xls"
                                    className="dash-btn Excel-button"
                                    buttonText="Excel"
                                >
                                </ReactHTMLTableToExcel>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-table">
                        <CustomPagination items={customerlist} TotalItems={customerlist?.length} onChangePage={() => { }} component={(items, index) => {
                            return (
                                <div className="table-responsive" key={index + 2}>
                                    <table className="table-responsive-lg" id="table-to-xls">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Section</th>
                                                <th scope="col">Role</th>
                                                <th scope="col">Status</th>
                                                <th scope="col" style={{ textAlign: 'center' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items?.filter(x =>Object.values(x).some(value =>value?.toString().toLowerCase().includes(search.toLowerCase())))?.map((item, index) => {
                                                return (
                                                    <tr key={index + 2}>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.mobile}</td>
                                                        <td style={item?.section == 'Not Assign' ? { color: 'red', width: 300 } : { color: '#000', width: 300 }}>{item?.section}</td>
                                                        <td>{item?.role}</td>
                                                        <td><div style={item?.active ? { color: 'green' } : { color: 'red' }}>{item?.active ? 'Active' : 'InActive'}</div></td>
                                                        <td>
                                                            <div className="custom-row" style={{ justifyContent: 'space-evenly' }}>
                                                                <div className="edit" onClick={() => { setEditData(item); setShowEdit(true) }}>
                                                                    <FiEdit3 size={20} color={'#8aae9a'} />
                                                                </div>
                                                                <div className="delet" onClick={() => { setDeletData(item); setShowDelet(true) }}>
                                                                    <AiOutlineDelete size={22} color='#cf4545' />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>

                                    </table>
                                </div>
                            )
                        }}
                        />
                    </div>
                </div>
            </div >
            {
                (showedit && editdata) &&
                <EditEmployee
                    showmodal={showedit}
                    Hidemodal={(e) => { onHideEdit(e) }}
                    data={editdata}
                />
            }
            {
                (showdelet && deletdata) &&
                <VerificationPopup
                    showmodal={showdelet}
                    Hidemodal={(e) => { onHideDelet(e) }}
                    data={deletdata}
                    onClick={() => { onDelet() }}
                />
            }
            {
                showadd &&
                <AddEmployee
                    showmodal={showadd}
                    Hidemodal={(e) => { onHideAdd(e) }}
                />
            }
        </>
    );
}

export default DashboardCustomer