
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/Style/Custom.css';
import '../src/Style/Responsive.css';
import { Provider } from "react-redux";
import { store } from './Store/Store';
import LoadingScreen from './Screens/LoadingScreen';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";


const App = () => {

  return (

    <div className='App'>
      <Provider store={store} >
        <LoadingScreen />
      </Provider>
    </div>

  );
}

export default App;
