import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import CommonService from '../../Services/CommonService';
import { useDispatch } from "react-redux";
import { changeLoader } from "../../Actions/AuthAction";
import { toast } from 'react-toastify';
import { IoMdSearch } from "react-icons/io";
import TextInput from "../../Component/TextInput";


const ReportSummary = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState([])
    const [search, setSearch] = useState('')
    const [total, setTotal] = useState({ totalnew: 0, totalprocess: 0, totalresolved: 0 })

    const getSummary = async () => {
        dispatch(changeLoader(true))
        await CommonService.GraphByStatus().then((res) => {
            if (res != null) {
                let NewSum = 0;
                let InProcessSum = 0;
                let ResolvedSum = 0
                res?.grievancebysections?.map((item) => {
                    if (item?.statusCounts?.filter((x) => x?.status == 'New') == '') {
                        item?.statusCounts.push({ "count": 0, "status": "New" })
                    }
                    if (item?.statusCounts?.filter((x) => x?.status == 'In Process') == '') {
                        item?.statusCounts.push({ "count": 0, "status": "In Process" })
                    }
                    if (item?.statusCounts?.filter((x) => x?.status == 'Resolved') == '') {
                        item?.statusCounts.push({ "count": 0, "status": "Resolved" })
                    }
                    item?.statusCounts?.filter((x) => x?.status == 'New')?.map((x) => { NewSum += x?.count })
                    item?.statusCounts?.filter((x) => x?.status == 'In Process')?.map((x) => { InProcessSum += x?.count })
                    item?.statusCounts?.filter((x) => x?.status == 'Resolved')?.map((x) => { ResolvedSum += x?.count })
                })
                setData(res?.grievancebysections)
                setTotal({
                    totalnew: NewSum,
                    totalprocess: InProcessSum,
                    totalresolved: ResolvedSum
                });
            }
            else {
                toast.error('Something Wrong')
            }
        })
        dispatch(changeLoader(false))
    }

    useEffect(() => {
        getSummary()
    }, [])

    return (

        <div className="dashboard-report">
            <div className="container-fluid">
                <div className="row" style={{ alignItems: 'center' }}>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <TextInput
                            placeholder={'Search..'}
                            value={search}
                            lefticon={<IoMdSearch size={20} />}
                            onchange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <div className="buttons">
                            <ReactHTMLTableToExcel
                                filename="ReportSummary"
                                sheet="ReportSummarySheet"
                                table="table-to-xls"
                                className="dash-btn Excel-button"
                                buttonText="Excel"
                            >
                            </ReactHTMLTableToExcel>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4 mb-2">
                        <div style={{ backgroundColor: '#fff', padding: 20, borderRadius: 20 }}>
                            <div className="table-responsive">
                                <table className="table-bordered table-responsive-lg" id="table-to-xls">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Section Name</th>
                                            <th scope="col">New</th>
                                            <th scope="col">In Process</th>
                                            <th scope="col">Resolved</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.filter(x => x?.section.toLowerCase().includes(search.toLowerCase()))?.map((item, index) => {
                                            return (
                                                <tr key={index + 2}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.section}</td>
                                                    <td>{parseInt(item?.statusCounts?.filter(x => x.status == 'New')?.map((d) => { return d?.count }).toString())}</td>
                                                    <td>{parseInt(item?.statusCounts?.filter(x => x.status == 'In Process')?.map((d) => { return d?.count }).toString())}</td>
                                                    <td>{parseInt(item?.statusCounts?.filter(x => x.status == 'Resolved')?.map((d) => { return d?.count }).toString())}</td>
                                                    <td>{parseInt(item?.statusCounts?.filter(x => x.status == 'New')?.map((d) => { return d?.count }).toString()) + parseInt(item?.statusCounts?.filter(x => x.status == 'In Process')?.map((d) => { return d?.count }).toString()) + parseInt(item?.statusCounts?.filter(x => x.status == 'Resolved')?.map((d) => { return d?.count }).toString())} </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td colSpan="2" style={{ textAlign: 'center', fontSize: 16, fontWeight: '500' }}>Total</td>
                                            <td>
                                                {data
                                                    ?.filter(item => item?.section.toLowerCase().includes(search.toLowerCase()))
                                                    ?.flatMap(item => item?.statusCounts)
                                                    ?.filter(statusCount => statusCount?.status === 'New')
                                                    ?.reduce((sum, statusCount) => sum + (statusCount?.count || 0), 0)
                                                }
                                            </td>
                                            <td>
                                                {data
                                                    ?.filter(item => item?.section.toLowerCase().includes(search.toLowerCase()))
                                                    ?.flatMap(item => item?.statusCounts)
                                                    ?.filter(statusCount => statusCount?.status === 'In Process')
                                                    ?.reduce((sum, statusCount) => sum + (statusCount?.count || 0), 0)
                                                }
                                            </td>
                                            <td>
                                                {data
                                                    ?.filter(item => item?.section.toLowerCase().includes(search.toLowerCase()))
                                                    ?.flatMap(item => item?.statusCounts)
                                                    ?.filter(statusCount => statusCount?.status === 'Resolved')
                                                    ?.reduce((sum, statusCount) => sum + (statusCount?.count || 0), 0)
                                                }
                                            </td>
                                            <td>
                                                {data
                                                    ?.filter(item => item?.section.toLowerCase().includes(search.toLowerCase()))
                                                    ?.flatMap(item => item?.statusCounts)
                                                    ?.filter(statusCount => ['New', 'Resolved', 'In Process'].includes(statusCount?.status))
                                                    ?.reduce((acc, statusCount) => acc + (statusCount?.count || 0), 0)
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}

export default ReportSummary