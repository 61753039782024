import { LOADER, LOGIN, LOGOUT, LOGINUSER, DASHBOARDSTRETCH } from '../Actions/ActionType';

const initialState = {
    loading: false,
    login: 'not login',
    loginuser: {},
    size: false
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {

        case LOADER:
            return {
                ...state,
                loading: action.loading,
            };
        case LOGIN:
            return {
                ...state,
                login: action.login,
            };
        case LOGINUSER:
            return {
                ...state,
                loginuser: action.loginuser,
            };
        case LOGOUT:
            return {
                ...state,
                login: 'not login'
            };
        case DASHBOARDSTRETCH:
            return {
                ...state,
                size: action.size,
            };
        default:
            return state;
    }
};

export default AuthReducer;