import axios from 'axios';

const GET = (host) => {
    console.log(host)
    return axios.get(host)
        .then(({ data }) => {
            return data;
        })
        .catch(async (error) => {
            return null;
        });
}

const POST = (host, data) => {
    console.log(host)
    return axios.post(host, data)
        .then(({ data }) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
}


const PUT = async (host, data) => {
    console.log(host)
    return axios.put(host, data)
        .then(async ({ data }) => {
            return data;
        })
        .catch(async (error) => {
            return null;
        });
}

const DELETE = async (host, data) => {
    console.log(host)
    return axios.delete(host, data)
        .then(async ({ data }) => {
            return data;
        })
        .catch(async (error) => {
            return null;
        });
}



export default { GET, POST, PUT, DELETE };