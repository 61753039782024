import React, { useState, useEffect } from "react";
import Button from "../Component/Button";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {

    const navigate = useNavigate();

    const onBack = () => {
        navigate('/')
    }

    return (
        <div className="notfound-page page">
            <div className="logo">
                <img src="assets/transparent-logo.png" alt="logo" width={40} />
                <div className="heading">
                    <h3>Bhagalpur Municipal Corporation</h3>
                </div>
            </div>
            <div className="detail">
                <h6 className="mt-2" style={{ fontSize: 17, lineHeight: '30px', textAlign: 'center', color: '#6f757e' }}>Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.</h6>
                <img src="/assets/notfound.jpg" alt="logo" width={'100%'} height={'100%'} />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        value="Go Back"
                        style={{ minWidth: 250 }}
                        onClick={() => { onBack() }}
                    />
                </div>
            </div>
        </div>

    )
}

export default PageNotFound