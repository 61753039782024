import React, { useEffect } from "react";
import ScreenRouting from "../Model/ScreenRouting";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Loader from '../Component/Loader';
import LocalStorage from "../Utils/LocalStorage";
import { saveLogin, LoginUser } from '../Actions/AuthAction';

const LoadingScreen = () => {

    const dispatch = useDispatch();

    const updateReduer = async () => {
        const templogin = await LocalStorage.GetData('login');
        const temploginuser = await LocalStorage.GetData('loginuser');
        if (templogin != null && templogin !== '' && templogin != undefined) {
            dispatch(saveLogin(templogin));
        }
        if (temploginuser != null && temploginuser != '' && temploginuser != undefined) {
            dispatch(LoginUser(JSON.parse(temploginuser)));
        }
    }


    useEffect(() => {
        updateReduer()
        //For Hide Inspect Element
        if (process.env.NODE_ENV != 'development') {
            document.addEventListener('contextmenu', function (e) {
                e.preventDefault();
            });
            document.addEventListener('keydown', function (e) {
                if (e.code == 'F12') {
                    e.preventDefault();
                }
            })
        }
    }, [])


    return (
        <>
            <ScreenRouting />
            <Loader />
            <ToastContainer autoClose={1500} position={'top-center'} theme={'colored'} style={{ fontSize: 20, width: 'auto' }} />
        </>
    );
}

export default LoadingScreen