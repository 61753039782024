
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Button';
import TextInput from '../TextInput';
import CommonService from '../../Services/CommonService';
import { useDispatch } from 'react-redux';
import { changeLoader } from '../../Actions/AuthAction';
import { toast } from 'react-toastify';
import useWindowDimensions from '../../Utils/WindowSize';


const AddUser = ({ showmodal, Hidemodal }) => {

    const dispatch = useDispatch();
    const { height } = useWindowDimensions();

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');


    const onSave = async () => {
        dispatch(changeLoader(true))
        await CommonService.AddNewUser(name, password).then(async (res) => {
            if (res != null) {
                if (res?.id != 0) {
                    setName('')
                    setPassword('')
                    toast.success('User Add Successfully')
                    Hidemodal('refresh')
                }
                else {
                    toast.error('Something Wrong')
                }
            }
        })
        dispatch(changeLoader(false))
    }


    return (
        <>
            <Modal
                show={showmodal}
                onHide={Hidemodal}
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body style={{ width: '100%', maxHeight: height - 50, minWidth: 600, borderRadius: 5, backgroundColor: '#fff', padding: 20, overflowY: 'auto' }}>
                    <div className='verify-popup'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='verify-header'>
                                    <h3 style={{ textAlign: 'center', color: '#CCC' }}>{'Add User'}</h3>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='edit-form'>
                                    <div className='mt-3'>
                                        <TextInput
                                            isRequired={true}
                                            label={'Enter name'}
                                            value={name}
                                            onchange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <TextInput
                                            isRequired={true}
                                            type="password"
                                            label={'Enter Password'}
                                            value={password}
                                            onchange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12 mt-3'>
                                <div className='editbtn'>
                                    <Button
                                        value={'Save'}
                                        disabled={(name == '' || password == '') ? true : false}
                                        onClick={() => { onSave() }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}


export default AddUser
