import React, { useEffect } from "react";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Cookies from 'universal-cookie';
import Layout from "./Layout";
import Login from "../Screens/Login";
import DashboardLayout from "./DashboardLayout";
import Dashboard from "../Screens/DashboardScreens/Dashboard";
import DashboardCustomer from "../Screens/DashboardScreens/DashboardCustomer";
import DashboardReport from "../Screens/DashboardScreens/DashboardReport";
import DashboadSetting from "../Screens/DashboardScreens/DashboardSetting";
import PageNotFound from "../Screens/PageNotFound";
import DashboardReportDetail from "../Screens/DashboardScreens/DashboardReportDetail";
import ReportSummary from "../Screens/DashboardScreens/ReportSummary";
import DashboardUser from "../Screens/DashboardScreens/DashboardUser";
import AccountDelete from "../Screens/AccountDelete";


const ScreenRouting = () => {

    const cookies = new Cookies();
    const logins = cookies.get('Login');

    useEffect(() => {
        cookies.get('Login');
    }, [])

    return (
        <Router>
            <Routes>
                <Route>
                    {(logins == 'false' || logins == undefined) ?
                        <Route element={<Layout />}>
                            <Route index path="/" element={<Login />} />
                        </Route>
                        :
                        <Route element={<DashboardLayout />}>
                            <Route index path="/" element={<Dashboard />} />
                            <Route path="/dashcustomer" element={<DashboardCustomer />} />
                            <Route path="/dashreport" element={<DashboardReport />} />
                            <Route path="/dashdetail" element={<DashboardReportDetail />} />
                            <Route path="/dashsetting" element={<DashboadSetting />} />
                            <Route path="/reportsummary" element={<ReportSummary />} />
                            <Route path="/dashuser" element={<DashboardUser />} />
                        </Route>
                    }
                    <Route path="*" element={<PageNotFound />} />
                    <Route path="/record/delete" element={<AccountDelete />} />
                </Route>
            </Routes>
        </Router>
    );
}

export default ScreenRouting