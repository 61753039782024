import React, { useEffect, useState } from "react";
import { AiFillFileText } from "react-icons/ai";
import { FaUser, FaUserCircle } from "react-icons/fa";
import { IoMdArrowDropup, IoMdArrowDropdown, IoIosHome, IoMdSettings } from 'react-icons/io';
import { useLocation, useNavigate } from "react-router-dom";
import LocalStorage from "../../Utils/LocalStorage";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { ChangeSideMenu, saveLogin } from "../../Actions/AuthAction";
import Cookies from "universal-cookie";


const SideMenue = () => {

    const cookies = new Cookies();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const mobilemode = useSelector((state) => state.AuthReducer.size)

    const [show, setShow] = useState(false);

    useEffect(() => {
        dispatch(ChangeSideMenu(false))
    }, [location])

    const onLogout = async () => {
        cookies.set('Login', 'false', { path: '/' });
        dispatch(saveLogin('not login'));
        await LocalStorage.RemoveData('loginuser')
        await LocalStorage.SetData('login', 'not login')
        window.location.replace(window.location.origin + '/')
    }

    return (
        <div className={`side-menue ${mobilemode ? 'responsive' : ""}`}>
            <div className="side-logo">
                <img src="assets/transparent-logo.png" alt="logo" width={80} />
            </div>
            <div className="tabs">
                <div className={`side-link ${location.pathname == '/' ? 'active' : 'unactive'} `} onClick={() => { navigate('/'); setShow(false); }}>
                    <IoIosHome size={22} color={location.pathname == '/' ? "#fff" : "#a9acb2"} />
                    <h6>Home</h6>
                </div>
                <div className={`side-link ${location.pathname == '/dashuser' ? 'active' : 'unactive'} `} onClick={() => { navigate('/dashuser'); setShow(false) }}>
                    <FaUserCircle size={20} color={location.pathname == '/dashuser' ? "#fff" : "#a9acb2"} />
                    <h6>User</h6>
                </div>
                <div className={`side-link ${location.pathname == '/dashcustomer' ? 'active' : 'unactive'} `} onClick={() => { navigate('/dashcustomer'); setShow(false) }}>
                    <FaUser size={18} color={location.pathname == '/dashcustomer' ? "#fff" : "#a9acb2"} />
                    <h6>Employee</h6>
                </div>
                <div style={show ? { backgroundColor: '#fcfbfb', borderRadius: 20 } : {}}>
                    <div className="side-link" onClick={() => { setShow(!show) }}>
                        <div className="side-heading">
                            <AiFillFileText size={20} color="#a9acb2" />
                            <h6>Report</h6>
                        </div>
                        <div className="side-arrow">
                            {!show ?
                                <IoMdArrowDropdown size={22} color="#a9acb2" />
                                :
                                <IoMdArrowDropup size={22} color="#a9acb2" />
                            }
                        </div>
                    </div>
                    {show &&
                        <div className="side-sub">
                            <ul>
                                <li className={`side-link ${(location.pathname == '/dashreport' || location.pathname == '/dashdetail') ? 'active' : 'unactive'} `} onClick={async () => { navigate('/dashreport'); await LocalStorage.SetData('ReportData', '0') }}>
                                    <a>Report Detail</a>
                                </li>
                                <li className={`side-link ${location.pathname == '/reportsummary' ? 'active' : 'unactive'} `} onClick={() => { navigate('/reportsummary') }}>
                                    <a>Report Summary</a>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
                <div className={`side-link ${location.pathname == '/dashsetting' ? 'active' : 'unactive'} `} onClick={() => { navigate('/dashsetting'); setShow(false) }}>
                    <IoMdSettings size={20} color={location.pathname == '/dashsetting' ? "#fff" : "#a9acb2"} />
                    <h6>Setting</h6>
                </div>
            </div>
            <div className="logout" onClick={() => { onLogout() }}>
                <RiLogoutCircleRLine size={22} />
                <h6>Logout</h6>
            </div>
        </div >

    );
}

export default SideMenue