import React from "react";


const Button = ({ ...props }) => {

    const { value, onClick, NewclassName, disabled, onDisable, style } = props;

    return (
        <div className={`${!disabled ? 'button' : ''} buttons`} onClick={!disabled ? onClick : onDisable}>
            <input type={'button'} className={`custom-button ${NewclassName != undefined && NewclassName} ${disabled && 'disable'} `} style={style} value={value} />
        </div>
    );
}

export default Button