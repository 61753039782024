import React from 'react';
import { Outlet } from 'react-router-dom';


const Layout = (props) => {

    const { children } = props;

    return (
        <React.Fragment>
            <div className="layout">
                <div className='main'>
                    <Outlet />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Layout;