import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
// import { nl } from "date-fns/locale";
import LocalStorage from "../Utils/LocalStorage";
import { MdDateRange } from 'react-icons/md';
import { BsCalendarX } from 'react-icons/bs';
// import moment from 'moment';

const CustomDatePicker = ({ ...props }) => {

    const { value, onChange, TimeZone, dateFormat, className, time } = props;

    const [locale, setlocale] = useState('')

    // const onCheckLanguage = async () => {
    //     var id = await LocalStorage.GetData('LanguageId');
    //     if (id != 1) {
    //         setlocale('')
    //     }
    //     else {
    //         setlocale(nl)
    //     }
    // }

    // useEffect(() => {
    //     onCheckLanguage()
    // }, [])

    const dateref = React.createRef()
    const [openpicker, setOpenPicker] = useState(false)

    return (
        <div className="datepicker-row">
            <DatePicker
                selected={value}
                onChange={onChange}
                className={className}
                locale={locale}
                placeholderText={'00-00-0000'}
                showMonthDropdown={true}
                timeFormat={'HH:mm'}
                open={openpicker}
                scrollableYearDropdown={true}
                onSelect={(e)=>{ setOpenPicker(false)}}
                calendarStartDay={1}
                showWeekNumbers={'true'}
                showYearDropdown={'true'}
                // injectTimes={[
                //     moment(new Date(), 1),
                //     moment(new Date(), 5),
                //     moment(new Date(), 59)
                // ]}
                onBlur={() => { setOpenPicker(false) }}
                onClickOutside={() => { setOpenPicker(false) }}
                setFocus={true}
                weekLabel={' '}
                ref={dateref}
                showTimeSelect={TimeZone === true ? true : false}
                // dateFormat={dateFormat ? dateFormat : 'dd-MM-yyyy' + ' ' + `${time}`}
                dateFormat={'dd-MM-yyyy'}
            />
            {!openpicker ?
                <div className="date-icon" onClick={() => { setOpenPicker(true); dateref?.current?.input?.focus(); }}>
                    <MdDateRange size={24} color={'#979191'} />
                </div>
                :
                <div className="date-icon" onClick={() => { setOpenPicker(false) }}>
                    <BsCalendarX size={20} color={'#979191'} />
                </div>
            }

        </div>
    );
}

export default CustomDatePicker