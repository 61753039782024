import React, { useState, useEffect } from "react";
import CustomPagination from "../../Component/CustomPagination";
import { useNavigate } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import CommonService from '../../Services/CommonService';
import { useDispatch } from "react-redux";
import { changeLoader } from "../../Actions/AuthAction";
import { Modal } from 'react-bootstrap';
import Button from "../../Component/Button";
import DropDown from "../../Component/Dropdown";
import { IoMdSearch } from "react-icons/io";
import { IoFilterOutline } from "react-icons/io5";
import CustomDatePicker from "../../Component/CustomDatePicker";
import { toast } from 'react-toastify';
import LocalStorage from "../../Utils/LocalStorage";
import { useReactToPrint } from 'react-to-print';
import { Parser } from 'html-to-react'
import TextInput from '../../Component/TextInput';


const DashboardReport = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [reportlist, setReportList] = useState([]);
    const [pdfreport, setPdfReport] = useState("");
    const [sectionlist, setSectionList] = useState([]);
    const [wardlist, setWardList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedsection, setSelectedSection] = useState();
    const [selectedward, setSelectedWard] = useState();
    const [startdate, setStartDate] = useState('');
    const [enddate, setEnddate] = useState('');
    const [formatestartdate, setFormateStartDate] = useState();
    const [formatenddate, setFormateEndDate] = useState();
    const [isPrinting, setIsPrinting] = useState(false);
    const [search, setSearch] = useState('')

    useEffect(() => {
        setFormateStartDate((startdate !== '' && startdate != null && startdate) ? ((new Date(startdate).getFullYear()) + '-' + ((new Date(startdate).getMonth() + 1) <= 9 ? '0' + (new Date(startdate).getMonth() + 1) : (new Date(startdate).getMonth() + 1)) + '-' + ((new Date(startdate).getDate()) <= 9 ? '0' + (new Date(startdate).getDate()) : (new Date(startdate).getDate()))) : '')
        setFormateEndDate((enddate !== '' && enddate != null && enddate) ? ((new Date(enddate).getFullYear()) + '-' + ((new Date(enddate).getMonth() + 1) <= 9 ? '0' + (new Date(enddate).getMonth() + 1) : (new Date(enddate).getMonth() + 1)) + '-' + ((new Date(enddate).getDate()) <= 9 ? '0' + (new Date(enddate).getDate()) : (new Date(enddate).getDate()))) : '')
    }, [enddate, startdate])


    const statuslist = [
        {
            "label": "New",
            "value": "New",
        },
        {
            "label": "In Process",
            "value": "In Process",

        },
        {
            "label": "Resolved",
            "value": "Resolved",
        }
    ]

    const getReports = async () => {
        await CommonService.GetAllSection().then((res) => {
            if (res != null) {
                setSectionList(res)
            }
        })
        await CommonService.GetAllWard().then((res) => {
            if (res != null) {
                setWardList(res)
            }
        })
    }

    const UpdateRecord = async () => {
        dispatch(changeLoader(true))
        let temp = await LocalStorage.GetData('ReportData')
        let CheckPreviousStorage = JSON.parse(temp);
        if (CheckPreviousStorage != null) {
            if (CheckPreviousStorage != 0) {
                let res = JSON.parse(temp);
                setStartDate(new Date(res?.startdate))
                setEnddate(new Date(res?.enddate))
                setSelectedStatus(res?.selectedStatus)
                setSelectedWard(res?.selectedward)
                setSelectedSection(res?.selectedsection)
                await CommonService.GenerateReport(res?.selectedStatus?.value, res?.selectedward?.value, res?.selectedsection?.value, res?.formatestartdate, res?.formatenddate).then((res) => {
                    if (res != null) {
                        if (res?.success) {
                            setReportList(res?.data)
                        }
                        else {
                            toast.error('No Record Found')
                            setReportList([])
                        }
                    }
                })
                await CommonService.GenerateReportPDF(res?.selectedStatus?.value, res?.selectedward?.value, res?.selectedsection?.value, res?.formatestartdate, res?.formatenddate).then((res) => {
                    if (res != null) {
                        if (res?.success) {
                            setPdfReport(res?.reportPdf)
                        }
                        else {
                            setPdfReport("")
                        }
                    }
                })
            }
        }
        dispatch(changeLoader(false))
    }


    useEffect(() => {
        setIsPrinting(false)
        getReports()
        UpdateRecord()
    }, [])

    const onClerFilter = () => {
        setSelectedWard('')
        setSelectedSection('')
        setSelectedStatus('')
        setFormateEndDate('')
        setFormateStartDate('')
        setStartDate('')
        setEnddate('')
    }

    const onSearchFilter = async () => {
        setVisible(false)
        dispatch(changeLoader(true))
        await CommonService.GenerateReport(selectedStatus?.value, selectedward?.value, selectedsection?.value, formatestartdate, formatenddate).then((res) => {
            if (res != null) {
                if (res?.success) {
                    setReportList(res?.data)
                }
                else {
                    toast.error('No Record Found')
                    setReportList([])
                }
            }
        })
        await CommonService.GenerateReportPDF(selectedStatus?.value, selectedward?.value, selectedsection?.value, formatestartdate, formatenddate).then((res) => {
            if (res != null) {
                if (res?.success) {
                    setPdfReport(res?.reportPdf)
                }
                else {
                    setPdfReport("")
                }
            }
        })
        let temp = {
            'selectedStatus': selectedStatus,
            'selectedward': selectedward,
            'selectedsection': selectedsection,
            'startdate': startdate,
            'enddate': enddate,
            'formatestartdate': formatestartdate,
            'formatenddate': formatenddate,
        }
        await LocalStorage.SetData('ReportData', JSON.stringify(temp))
        dispatch(changeLoader(false))
    }


    //Generate PDF
    const printRef = React.useRef(null);
    const promiseResolveRef = React.useRef(null);

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            promiseResolveRef.current = null;
            setIsPrinting(false);
        }
    });

    const PdfContent = (
        <div ref={printRef}>
            {Parser().parse(pdfreport)}
        </div>
    )

    return (
        <>
            <div className="dashboard-report">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <TextInput
                                placeholder={'Search..'}
                                value={search}
                                lefticon={<IoMdSearch size={20} />}
                                onchange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="button-group">
                                <Button
                                    value="PDF"
                                    NewclassName="pdf-button"
                                    disabled={reportlist == '' ? true : false}
                                    onClick={() => { handlePrint() }}
                                />
                                {reportlist == '' ?
                                    <Button
                                        value="Excel"
                                        NewclassName="Excel-button"
                                        disabled={reportlist == '' ? true : false}
                                        onClick={() => { }}
                                    />
                                    :
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        filename="report"
                                        sheet="ReportSheet"
                                        table="table-to-xls"
                                        className="download-table-xls-button dash-btn Excel-button"
                                        buttonText="Excel"
                                    >
                                    </ReactHTMLTableToExcel>

                                }
                                <div style={{ marginLeft: 12 }}>
                                    <div className='filterbtn' onClick={() => { setVisible(true); }}>
                                        <IoFilterOutline size={25} />
                                        <h5 style={{ marginLeft: 10, fontWeight: '500', marginBottom: 0, fontSize: 16 }}>Filter</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-table">
                        <CustomPagination items={reportlist} TotalItems={reportlist?.length} onChangePage={() => { }} component={(items, index) => {
                            return (
                                <div className="table-responsive" key={index + 2}>
                                    <table className="table-responsive-lg" id="table-to-xls">
                                        <thead>
                                            <tr>
                                                <th scope="col">Id</th>
                                                <th scope="col">Complaint Date</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Section</th>
                                                <th scope="col">Ward</th>
                                                <th scope="col">Complaint</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items?.filter(x => Object.values(x).some(value => value?.toString().toLowerCase().includes(search.toLowerCase())))?.map((item, index) => {
                                                return (
                                                    <tr key={index + 2} style={{ cursor: 'pointer' }} onClick={() => { navigate('/dashdetail', { state: item?.id }) }}>
                                                        <td>{item?.id}</td>
                                                        <td>{item?.cdate}</td>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.mobile}</td>
                                                        <td>{item?.sectionNavigation?.section}</td>
                                                        <td>{item?.ward}</td>
                                                        <td className={"detail"}>{item?.complaint}</td>
                                                        <td>{item?.status}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            )
                        }}
                        />
                    </div>

                </div>
            </div >
            {isPrinting && PdfContent}
            {visible &&
                <Modal
                    show={visible}
                    onHide={() => { setVisible(false) }}
                    animation={true}
                    className="filter"
                >
                    <Modal.Body style={{ width: '100%', height: '100%', backgroundColor: '#fff', padding: 5 }}>
                        <div className='filterbox'>
                            <div className='heading mb-3'>
                                <div className='name'>
                                    <h6 style={{ color: '#7392db', textTransform: 'uppercase' }}>Filter</h6>
                                </div>
                                <div className='slect' onClick={() => { onClerFilter() }}>
                                    <h6 style={{ color: '#ef3b31', textTransform: 'uppercase', cursor: 'pointer' }}>Clear</h6>
                                </div>
                            </div>
                            <div className='heading-status'>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h6 >Status</h6>
                                    {selectedStatus == undefined &&
                                        <h6 style={{ color: 'red' }}>*</h6>
                                    }
                                </div>
                            </div>
                            <div className='select-status mb-3'>
                                <DropDown
                                    placeholder={'Select Status'}
                                    Multiple={false}
                                    options={statuslist?.map((item) => { return { 'value': item?.value, 'label': item?.label } })}
                                    onChange={(item) => { setSelectedStatus(item) }}
                                    value={selectedStatus}
                                />
                            </div>
                            <div className='heading-status'>
                                <h6>Ward</h6>
                            </div>
                            <div className='select-status mb-3'>
                                <DropDown
                                    placeholder={'Select Ward'}
                                    Multiple={false}
                                    options={wardlist?.map((item) => { return { 'value': item?.id, 'label': item?.wardno } })}
                                    onChange={(item) => { setSelectedWard(item) }}
                                    value={selectedward}
                                />
                            </div>
                            <div className='heading-status'>
                                <h6>Section</h6>
                            </div>
                            <div className='select-status mb-3'>
                                <DropDown
                                    placeholder={'Select Section'}
                                    Multiple={false}
                                    options={sectionlist?.map((item) => { return { 'value': item?.id, 'label': item?.section } })}
                                    onChange={(item) => { setSelectedSection(item) }}
                                    value={selectedsection}
                                />
                            </div>
                            <div className='heading-status mb-3'>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h6 >Start Date</h6>
                                    {startdate == '' &&
                                        <h6 style={{ color: 'red' }}>*</h6>
                                    }
                                </div>
                                <CustomDatePicker
                                    value={startdate}
                                    TimeZone={false}
                                    onChange={(date) => { setStartDate(date) }}
                                />
                            </div>
                            <div className='heading-status mb-4'>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h6 >End Date</h6>
                                    {enddate == '' &&
                                        <h6 style={{ color: 'red' }}>*</h6>
                                    }
                                </div>
                                <CustomDatePicker
                                    value={enddate}
                                    TimeZone={false}
                                    onChange={(date) => { setEnddate(date) }}
                                />
                            </div>
                            <Button
                                value={'Save'}
                                disabled={(startdate == '' || enddate == '' || selectedStatus == undefined) ? true : false}
                                onClick={() => { onSearchFilter() }}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
}

export default DashboardReport