import React, { useState } from "react";
import TextInput from "../../Component/TextInput";
import Button from "../../Component/Button";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import CommonService from '../../Services/CommonService';
import { useDispatch } from "react-redux";
import { changeLoader, LoginUser } from '../../Actions/AuthAction';
import LocalStorage from "../../Utils/LocalStorage";


const DashboadSetting = () => {

    const dispatch = useDispatch();

    const [previouspass, setPreviouspass] = useState('');
    const [newpass, setNewPass] = useState('')
    const [confirmpass, setConfirmPass] = useState('')

    const loginuser = useSelector((state) => state.AuthReducer.loginuser)

    const onUpdate = async () => {
        if (loginuser?.password == newpass || loginuser?.password == confirmpass) {
            toast.error('Old and new passwords must be different')
            return
        }
        if (newpass != confirmpass) {
            toast.error('Password not match')
            return
        }
        dispatch(changeLoader(true))
        loginuser['password'] = newpass
        await CommonService.ChangePassword(loginuser).then(async (res) => {
            if (res != null) {
                if (res?.success) {
                    res['data']['password'] = newpass
                    dispatch(LoginUser(res?.data));
                    await LocalStorage.SetData('loginuser', JSON.stringify(res?.data))
                    toast.success(res?.message)
                    setNewPass('')
                    setConfirmPass('')
                }
                else {
                    toast.error(res?.message)
                }
            }
        })
        dispatch(changeLoader(false))
    }

    return (
        <div className="dashboard-setting">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="dash-box">
                            <TextInput
                                isRequired={true}
                                disabled={true}
                                type="password"
                                label={'Previous Password'}
                                value={loginuser?.password}
                                onchange={(e) => setPreviouspass(e.target.value)}
                            />
                            <div className="mt-4">
                                <TextInput
                                    isRequired={true}
                                    label={'New Password'}
                                    type="password"
                                    value={newpass}
                                    onchange={(e) => setNewPass(e.target.value)}
                                />
                            </div>
                            <div className="mt-4 mb-4">
                                <TextInput
                                    isRequired={true}
                                    label={'Confirm Password'}
                                    type="password"
                                    value={confirmpass}
                                    onchange={(e) => setConfirmPass(e.target.value)}
                                />
                            </div>
                            <Button
                                value="Update"
                                disabled={(newpass == '' || confirmpass == '') ? true : false}
                                onClick={() => { onUpdate() }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default DashboadSetting



