import React from "react";
import Select from "react-select";

const DropDown = ({ ...props }) => {

    const { placeholder , options, value, onChange, Multiple ,isClearable ,defaultValue , defaultInputValue} = props;

    return (

        <Select
            placeholder={placeholder}
            options={options}
            value={value}
            defaultValue={defaultValue}
            defaultInputValue={defaultInputValue}
            onChange={onChange}
            isMulti={Multiple}
            isClearable={isClearable}
        />
    )

}

export default DropDown