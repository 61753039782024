
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from './Button';
import useWindowDimensions from '../Utils/WindowSize';

const VerificationPopup = ({ showmodal, Hidemodal, onClick }) => {

    const { height } = useWindowDimensions()

    return (
        <>
            <Modal
                show={showmodal}
                onHide={Hidemodal}
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body style={{ width: '100%', maxHeight: height - 50, minWidth: 500, borderRadius: 5, overflow: 'hidden', backgroundColor: '#fff', padding: 0 }}>
                    <div className='verify-popup'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='verify-header'>
                                    <h4 style={{ textAlign: 'center', color: '#fff', background: '#f3a71b', padding: 15 , fontSize:20 , fontWeight:500 }}>{'Delete Confirmation'}</h4>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm- pt-3'>
                                <div className='verify-body'>
                                    <h5 style={{ textAlign: 'center', color: '#CCC', marginTop: 10 , fontSize:18 , fontWeight:400 }}>{'Are you sure you want to delete this record'}</h5>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12 p-4 pb-3 pt-4'>
                                <div className='verify-button'>
                                    <div style={{ width: '45%' }}>
                                        <Button
                                            value={'Cancel'}
                                            style={{ height: 45, background: '#e43737', borderColor: '#e43737' }}
                                            onClick={() => { Hidemodal() }}
                                        />
                                    </div>
                                    <div style={{ width: '45%' }}>
                                        <Button
                                            value={'Yes'}
                                            style={{ height: 45, background: '#106e9db5', borderColor: '#106e9db5' }}
                                            onClick={onClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}


export default VerificationPopup
