import React from "react";

const TextInput = ({ ...props }) => {

    const { label, isRequired, placeholder, value, onchange, type, onIconClick, inputtype, disabled = false, lefticon } = props;

    return (
        <div className="input-field">
            <div className="row">
                {label &&
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-label">
                            <h5 style={{ fontSize: 14, fontWeight: 400, color: '#6f757e' }}>{label}</h5>
                            {(isRequired && value == '') &&
                                <h6>required *</h6>
                            }
                        </div>
                    </div>
                }
                <div className={"col-lg-12 col-md-12 col-sm-12"}>
                    <div className="inputs">
                        <input
                            className="input-style"
                            type={type != undefined ? type : 'text'}
                            placeholder={placeholder}
                            value={value}
                            style={lefticon ? { paddingRight: 50 } : {}}
                            disabled={disabled}
                            onChange={onchange}
                        />
                        {lefticon &&
                            <div className={`${inputtype == 'password' && 'passicon'} inputicon`} style={{ width: 32, height: 32, textAlign: 'center', verticalAlign: 'middle' }} onClick={onIconClick}>
                                {lefticon}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>

    );
}

export default TextInput