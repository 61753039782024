import React, { useState, useEffect } from "react";
import CustomPagination from "../../Component/CustomPagination";
import { useNavigate } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { AiOutlineDelete } from 'react-icons/ai';
import { IoMdSearch } from "react-icons/io";
import CommonService from '../../Services/CommonService';
import { useDispatch } from "react-redux";
import { changeLoader } from "../../Actions/AuthAction";
import { toast } from 'react-toastify';
import VerificationPopup from '../../Component/VerificationPopup';
import AddUser from "../../Component/DashboardComponent/AddUser";
import TextInput from "../../Component/TextInput";

const DashboardUser = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [customerlist, setCustomerList] = useState([]);
    const [deletdata, setDeletData] = useState([]);
    const [showdelet, setShowDelet] = useState(false);
    const [showadd, setShowAdd] = useState(false);
    const [search, setSearch] = useState('')


    const getAllUsers = async () => {
        dispatch(changeLoader(true))
        await CommonService.GetAllUser().then((res) => {
            if (res != null) {
                setCustomerList(res)
            }
            else {
                toast.error('Something Wronng')
            }
        })
        dispatch(changeLoader(false))
    }

    useEffect(() => {
        getAllUsers()
    }, [])

    const onHideDelet = (e) => {
        setDeletData([])
        setShowDelet(false)
    }

    const onHideAdd = (e) => {
        setShowAdd(false)
        if (e == 'refresh') {
            getAllUsers()
        }
    }

    const onDelet = async () => {
        dispatch(changeLoader(true))
        await CommonService.DeleteUser(deletdata?.id).then((res) => {
            if (res != null) {
                if (res?.success) {
                    toast.success(res?.message)
                    setShowDelet(false)
                    setDeletData([])
                    getAllUsers()
                }
                else {
                    toast.error(res?.message)
                }
            }
        })
        dispatch(changeLoader(false))
    }


    return (
        <>
            <div className="dashboard-customer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <TextInput
                                placeholder={'Search..'}
                                value={search}
                                lefticon={<IoMdSearch size={20} />}
                                onchange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="buttons">
                                <input type={'button'} value='Add New' className="dash-btn Add-button" onClick={() => { setShowAdd(true) }} />
                                <ReactHTMLTableToExcel
                                    filename="UserList"
                                    sheet="UserSheet"
                                    table="table-to-xls"
                                    className="dash-btn Excel-button"
                                    buttonText="Excel"
                                >
                                </ReactHTMLTableToExcel>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-table">
                        <CustomPagination items={customerlist} TotalItems={customerlist?.length} onChangePage={() => { }} component={(items, index) => {
                            return (
                                <div className="table-responsive" key={index + 2}>
                                    <table className="table-responsive-lg" id="table-to-xls">
                                        <thead>
                                            <tr>
                                                <th scope="col">Id</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Password</th>
                                                <th scope="col">Status</th>
                                                <th scope="col" style={{ textAlign: 'center' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items?.filter(x => x?.username.toLowerCase().includes(search.toLowerCase()))?.map((item, index) => {
                                                return (
                                                    <tr key={index + 2}>
                                                        <td>{item?.id}</td>
                                                        <td>{item?.username}</td>
                                                        <td className="password-text">{item?.password}</td>
                                                        <td><div style={item?.active ? { color: 'green' } : { color: 'red' }}>{item?.active ? 'Active' : 'UnActive'}</div></td>
                                                        <td>
                                                            <div className="custom-row" style={{ justifyContent: 'space-evenly' }}>
                                                                <div className="delet" onClick={() => { setDeletData(item); setShowDelet(true) }}>
                                                                    <AiOutlineDelete size={22} color='#cf4545' />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>

                                    </table>
                                </div>
                            )
                        }}
                        />
                    </div>
                </div>
            </div >
            {
                (showdelet && deletdata) &&
                <VerificationPopup
                    showmodal={showdelet}
                    Hidemodal={(e) => { onHideDelet(e) }}
                    data={deletdata}
                    onClick={() => { onDelet() }}
                />
            }
            {
                showadd &&
                <AddUser
                    showmodal={showadd}
                    Hidemodal={(e) => { onHideAdd(e) }}
                />
            }
        </>
    );
}

export default DashboardUser